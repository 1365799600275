<template>
  <v-card>
    <v-alert
      :value="success"
      type="success"
      class="my-0"
    >Тип оплаты удален</v-alert>
    <v-card-title
      class="px-4"
    >
      <span class="headline">Удалить?</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-actions
      class="px-4 py-3"
    >
      <v-btn
        @click.native="cancel()"
      >Отмена</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="error"
        @click="submitForm()"
      >Удалить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      success: false,
    };
  },
  methods: {
    cancel() {
      this.success = false;
      this.$emit('cancel');
    },
    submitForm() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payment-types/${this.id}`;

      axios
        .delete(url)
        .then(() => {
          this.success = true;
          setTimeout(() => {
            this.$emit('cancel');
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
