<template>
  <v-card>
    <v-alert
      :value="createdSuccess"
      type="success"
      class="my-0"
    >Тип оплаты добавлен</v-alert>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card-title
        class="px-4"
      >
        <span class="headline">Добавление типа оплаты</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
        class="px-4"
      >
        <v-text-field
          label="Название"
          :rules="[v => !!v || 'Заполните поле']"
          v-model="editedItem.name"
        ></v-text-field>

        <v-text-field
          label="Код"
          :rules="[v => !!v || 'Заполните поле']"
          v-model="editedItem.code"
        ></v-text-field>
        
        <v-autocomplete
          label="Клиент"
          :items="clientsList"
          item-text="name"
          item-value="id"
          v-model.number="editedItem.client_id"
          :rules="[v => !!v || 'Заполните поле']"
          hide-details
          no-data-text="Не надено"
          clearable
          :search-input.sync="searchClients"
          class="mb-4"
        ></v-autocomplete>

        <v-select
          label="Тип клиента"
          :items="paymentOptionList"
          item-text="name"
          item-value="id"
          v-model="editedItem.payment_option"
          hide-details
          class="mb-4"
        ></v-select>

        <!-- Vt Payment Option-->
        <template v-if="editedItem.payment_option == 1">
          <v-text-field
            label="device ip"
            :rules="[v => !!v || 'Заполните поле']"
            v-model="editedItem.device_ip"
          ></v-text-field>

          <v-text-field
            label="VAT"
            :rules="[v => !!v || 'Заполните поле']"
            v-model="editedItem.vat"
          ></v-text-field>
        </template>

        <!-- Bank Payment Option-->
        <template v-if="editedItem.payment_option == 2">
          <v-text-field
            label="Bank Token"
            :rules="[v => !!v || 'Заполните поле']"
            v-model="editedItem.bank_token"
          ></v-text-field>
          
          <v-text-field
            label="Bank Success Url"
            :rules="[v => !!v || 'Заполните поле']"
            v-model="editedItem.bank_success_url"
          ></v-text-field>

          <v-text-field
            label="Bank Fail Url"
            :rules="[v => !!v || 'Заполните поле']"
            v-model="editedItem.bank_fail_url"
          ></v-text-field>
        </template>

        <v-checkbox
          label="Системный"
          v-model="editedItem.system"
          color="primary"
        ></v-checkbox>

        <v-checkbox
          label="Close of Day"
          v-model="editedItem.is_close"
          color="primary"
        ></v-checkbox>

      </v-card-text>
      <v-card-actions
        class="px-4 pb-4"
      >
        <v-btn
          @click.native="cancel()"
        >Отмена</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="info"
          @click="submitForm"
        >Сохранить</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      editedItem: {
        name: null,
        code: null,
        system: true,
        device_ip:null,
        vat:null,
        client_id:null,
        payment_option:0,
        bank_success_url:null,
        bank_fail_url:null,
        bank_token:null,
        is_close: true,
      },
      createdSuccess: false,
      searchClients: '',
      timerClients: null,
      clientsList: [],
      paymentOptionList: [
        {
          id: 0,
          name: 'None',
        },
        {
          id: 1,
          name: 'VT',
        },
        {
          id: 2,
          name: 'Bank',
        },
      ]
    };
  },
  watch: {
    searchClients(val) {
      const findClient = this.clientsList.find((item) => item.name === val);
      if (findClient) return false;

      if (val && val.length >= 3) {
        if (this.timerClients) clearTimeout(this.timerClients);

        this.timerClients = setTimeout(() => {
          this.getClients(val);
        }, 500);
      } else {
        this.clientsList = [];
      }
    },
  },
  methods: {
    getClients(searchVal) {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/clients`;

      axios
        .get(url, {
          params: {
            name_or_phone: searchVal,
            page_limit: 999,
          },
        })
        .then((response) => {
          this.clientsList = response.data.map((item) => {
            return {
              name: `${item.name} (${item.phone})`,
              id: item.id,
            };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancel() {
      this.editedItem = {};
      this.createdSuccess = false;
      this.$emit('cancel');
    },
    submitForm() {
      const validate = this.$refs.form.validate();
      if (validate) {
        const propsItem = Object.assign({}, this.editedItem);
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payment-types`;

        axios
          .post(url, propsItem)
          .then(() => {
            this.createdSuccess = true;

            setTimeout(() => {
              this.$emit('cancel');
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>
